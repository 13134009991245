export default {
  quest: '任务',
  ongoing: '进行中',
  completed: '已完成',
  expired: '过期',
  title: '标题',
  quester: '参与者',
  rewards: '奖励',
  timeline: '截止时间',
  operation: '操作',
  createQuest: '创建任务',
  setUp: '设置',
  entries: '任务条件',
  viewQuest: '查看任务',
  description: '描述',
  enterTitle: '输入一个标题...',
  enterDesc: '输入描述...',
  linkRedPacket: '关联红包',
  taskStart: '任务开始',
  taskEnd: '任务结束',
  chooseTemplate: '选择任务条件',
  saveSetting: '保存设置',
  giftBox: '红包',
  token: '币种',
  network: '网络',
  claimReward: '领取奖励',
  verify: '验证',
  taskDesc: '完成简单任务，轻松赚取加密礼盒.',
  randomPacket: '拼手气红包',
  normalPacket: '普通红包',
}
