export default {
  quest: 'Quest',
  ongoing: 'Ongoing',
  completed: 'Completed',
  expired: 'Expired',
  title: 'Title',
  quester: 'Quester',
  rewards: 'Rewards',
  timeline: 'Timeline',
  operation: 'Operation',
  createQuest: 'Create a quest',
  setUp: 'Set up',
  entries: 'Entries',
  viewQuest: 'View Quest',
  description: 'Description',
  enterTitle: 'Enter a quest title...',
  enterDesc: 'Enter Description...',
  linkRedPacket: 'Link Red Packet',
  taskStart: 'Task Starts',
  taskEnd: 'Task Ends',
  chooseTemplate: 'Choose a Template',
  saveSetting: 'Save Setting',
  giftBox: 'Giftbox',
  token: 'Token',
  network: 'Network',
  claimReward: 'Claim Reward',
  verify: 'Verify',
  taskDesc: 'Complete simple tasks and easily earn crypto gift boxes',
  randomPacket: 'Random packet',
  normalPacket: 'Normal packet',
}
