// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import React from 'react';

export async function getRoutes() {
  const routes = {"1":{"path":"/","redirect":"/questList","parentId":"@@/global-layout","id":"1"},"2":{"path":"/createQuest","parentId":"@@/global-layout","id":"2"},"3":{"path":"/questDetail","parentId":"@@/global-layout","id":"3"},"4":{"path":"/questList","parentId":"@@/global-layout","id":"4"},"@@/global-layout":{"id":"@@/global-layout","path":"/","isLayout":true}} as const;
  return {
    routes,
    routeComponents: {
'1': React.lazy(() => import( './EmptyRoute')),
'2': React.lazy(() => import(/* webpackChunkName: "p__createQuest__index" */'@/pages/createQuest/index.tsx')),
'3': React.lazy(() => import(/* webpackChunkName: "p__questDetail__index" */'@/pages/questDetail/index.tsx')),
'4': React.lazy(() => import(/* webpackChunkName: "p__questList__index" */'@/pages/questList/index.tsx')),
'@@/global-layout': React.lazy(() => import(/* webpackChunkName: "layouts__index" */'/Users/hucheng/nodeDance/project/redpocket-task-frontend/src/layouts/index.tsx')),
},
  };
}
